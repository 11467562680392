import React from 'react';

const TeamSection = () => {
    return (
        <section id="team" className="team section-bg" style={{ display: 'none' }}>
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Team</h2>
                    <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.</p>
                </div>

                <div className="row">

                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                        <div className="member" data-aos="fade-up" data-aos-delay="100">
                            <div className="member-img">
                                <img src="https://www.mehtasecurity.in/public/frontend/frontassets/img/team/team-1.jpg" className="img-fluid" alt="" />
                                <div className="social">
                                    <a href="#"><i className="bi bi-twitter"></i></a>
                                    <a href="#"><i className="bi bi-facebook"></i></a>
                                    <a href="#"><i className="bi bi-instagram"></i></a>
                                    <a href="#"><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>Walter White</h4>
                                <span>Chief Executive Officer</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
    <div className="member" data-aos="fade-up" data-aos-delay="200">
        <div className="member-img">
            <img src="https://www.mehtasecurity.in/public/frontend/frontassets/img/team/team-2.jpg" className="img-fluid" alt="" />
            <div className="social">
                <a href="#"><i className="bi bi-twitter"></i></a>
                <a href="#"><i className="bi bi-facebook"></i></a>
                <a href="#"><i className="bi bi-instagram"></i></a>
                <a href="#"><i className="bi bi-linkedin"></i></a>
            </div>
        </div>
        <div className="member-info">
            <h4>Sarah Jhonson</h4>
            <span>Product Manager</span>
        </div>
    </div>
</div>

<div className="col-lg-3 col-md-6 d-flex align-items-stretch">
    <div className="member" data-aos="fade-up" data-aos-delay="300">
        <div className="member-img">
            <img src="https://www.mehtasecurity.in/public/frontend/frontassets/img/team/team-3.jpg" className="img-fluid" alt="" />
            <div className="social">
                <a href="#"><i className="bi bi-twitter"></i></a>
                <a href="#"><i className="bi bi-facebook"></i></a>
                <a href="#"><i className="bi bi-instagram"></i></a>
                <a href="#"><i className="bi bi-linkedin"></i></a>
            </div>
        </div>
        <div className="member-info">
            <h4>William Anderson</h4>
            <span>CTO</span>
        </div>
    </div>
</div>

<div className="col-lg-3 col-md-6 d-flex align-items-stretch">
    <div className="member" data-aos="fade-up" data-aos-delay="400">
        <div className="member-img">
            <img src="https://www.mehtasecurity.in/public/frontend/frontassets/img/team/team-4.jpg" className="img-fluid" alt="" />
            <div className="social">
                <a href="#"><i className="bi bi-twitter"></i></a>
                <a href="#"><i className="bi bi-facebook"></i></a>
                <a href="#"><i className="bi bi-instagram"></i></a>
                <a href="#"><i className="bi bi-linkedin"></i></a>
            </div>
        </div>
        <div className="member-info">
            <h4>Amanda Jepson</h4>
            <span>Accountant</span>
        </div>
    </div>
</div>
                    
                </div>

            </div>
        </section>
    );
}

export default TeamSection;
