import React, { useState, useEffect } from 'react';
import DrawerHeader from '../../Custormer/component/layouts/dashboard/SideBar';
import CustomTable from './adminCustomTable';
import Box from '@mui/material/Box';
import axios from "axios";

const headCells = [
  {
    id: '_id',
    numeric: false,
    disablePadding: false,
    label: 'DEPOSITEID',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: true,
    label: 'AMOUNT',
  },
  {
    id: 'processedBy',
    numeric: false,
    disablePadding: false,
    label: 'PROCESSED BY',
  },
  {
    id: 'transactionId',
    numeric: false,
    disablePadding: false,
    label: 'TRANSACTIONID',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'DATE TIME',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
  }
];

const Ad_deposit = () => {
  const [data, setData] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/positionHoldingApi/deposits`);
      setData(response.data.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${backendUrl}/positionHoldingApi/deposits/${id}`);
      fetchData();
    } catch (error) {
      handleError(error);
    }
  };

  const handleUpdate = async (id, updatedRow) => {
    try {
      await axios.put(`${backendUrl}/positionHoldingApi/deposits/${id}`, updatedRow);
      fetchData();
    } catch (error) {
      handleError(error);
    }
  };

  const handleAdd = async (newRow) => {
    try {
      await axios.post(`${backendUrl}/positionHoldingApi/deposits`, newRow);
      fetchData();
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    let errorMessage = "An error occurred";
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }
    alert(errorMessage);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <DrawerHeader />
      <Box sx={{ flexGrow: 1, p: 3, mt: '64px' }}>
        <h1 style={{ marginBlock: "30px", alignItems: "center" }}>DEPOSIT </h1>
        <CustomTable
          headCells={headCells}
          rows={data}
          onDelete={handleDelete}
          onUpdate={handleUpdate}
          onAdd={handleAdd}
          setRows={setData}
        />
      </Box>
    </Box>
  );
};

export default Ad_deposit;
