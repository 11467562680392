import React from 'react';
import CounterAnimation from './CounterAnimation';


const CountSection = () => {
    return (
        <section id="counts" class="counts">
            <div class="container-md container-lg" data-aos="fade-down">

                <div class="row">

                    <div class="col-lg-3 col-md-6">
                        <div class="count-box">
                        
                        <i class="bi bi-emoji-smile"></i>
                            <CounterAnimation start={0} end={6890} duration={1000} class="purecounter" />
                            <p>Happy Clients</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
                        <div class="count-box">
                            <i class="bi bi-journal-richtext"></i>
                            <CounterAnimation start={0} end={2140} duration={1000} class="purecounter" />
                            <p>Projects</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                        <div class="count-box">
                            <i class="bi bi-headset"></i>
                            <CounterAnimation start={0} end={4463} duration={1000} class="purecounter" />
                            <p>Hours Of Support</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                        <div class="count-box">
                            <i class="bi bi-people"></i>
                            <CounterAnimation start={0} end={865} duration={1000} class="purecounter" />
                            <p>Hard Workers</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>)
}

export default CountSection